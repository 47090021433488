/* eslint-disable no-unused-vars */
import CidadeEstado from 'src/components/CidadeEstado/CidadeEstado'
import VeiculoCalcularPrecoModel from 'src/model/veiculo/VeiculoCalcularPrecoModel'
import ConsultaPlacaIcarrosModel from 'src/model/veiculo/ConsultaPlacaIcarrosModel'
import VeiculosEntradaModel from 'src/model/veiculo/VeiculosEntradaModel'
import BitrixDealCarecaModel from 'src/model/usuario/BitrixDealCarecaModel'
import EstadosBrasileiros from 'src/core/mixin/EstadosBrasileiros'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import ModalCadastro from 'src/components/ModalCadastro/ModalCadastro.vue'
import FormVeiculo from 'src/components/FormVeiculo/FormVeiculo.vue'
import PrecificadorModel from 'src/model/veiculo/PrecificadorModel'
import vTopFilters from 'src/core/filters/vTopFilters'
import { LocalStorage, clone } from 'quasar'

import MarcaModel from 'src/model/veiculo/MarcaModel'
import ModeloModel from 'src/model/veiculo/ModeloModel'
import VersaoModel from 'src/model/veiculo/VersaoModel'
import CombustivelModel from 'src/model/veiculo/CombustivelModel'

export default {
  name: 'QueroVender',
  components: { ModalCadastro, FormVeiculo },
  mixins: [NotificacaoMixin, CidadeEstado, vTopFilters, EstadosBrasileiros],
  meta () {
    return {
      title: 'Venda seu carro sem sair de casa - NetCarros',
      description: { name: 'description', content: 'Precisa vender seu carro? Receba uma oferta no seu carro em 5 minutos. Nós da NetCarros valorizamos seu carro usado. Receba o dinheiro em 24h. Acesse já!' },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-vender.jpg' },
      link: { material: { rel: 'canonical', href: 'https://www.netcarros.com.br' + this.$route.fullPath } },
      meta: [
        { 'property': 'og:title', 'content': 'Venda seu carro sem sair de casa - NetCarros' },
        { 'name': 'description', 'content': 'Precisa vender seu carro? Receba uma oferta no seu carro em 5 minutos. Nós da NetCarros valorizamos seu carro usado. Receba o dinheiro em 24h. Acesse já!' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-vender.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-vender.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'NetCarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  data () {
    return {

      modelBitrixDealCareca: new BitrixDealCarecaModel(),
      salvando: false,

      modelCalcularPreco: new VeiculoCalcularPrecoModel(),
      calculandoPreco: false,

      modelVeiculosEntrada: new VeiculosEntradaModel(),
      salvandoVeiculoEntrada: false,
      modalCalcularPreco: false,

      checkbox: false,
      anoLimite: 2010,
      kmLimite: 120000,
      resultadosCalcularPreco: null,

      diretivaSemMascara: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 0,
        masked: false
      },
      diretivaKm: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },

      logado: LocalStorage.getItem('logado'),

      modalRegistreConta: false,
      tipoModal: 3,

      // Listagens
      listagens: [
        'marca_id',
        'modelo_id',
        'versao_id',
        'combustivel_id'
      ],
      listas: [],
      models: [],
      carregamentos: [],
      filtrosListagens: []
      /* */
    }
  },
  watch: {
    'modelVeiculosEntrada.form.marca_id' (agora) {
      this.buscarListagemRequest('modelo_id', false, `marca_id:${agora}`)
    },
    'modelVeiculosEntrada.form.modelo_id' (agora) {
      this.buscarListagemRequest('versao_id', false, `modelo_id:${agora}`)
    }
  },
  created () {
    this.filtrosListagens = this.listagens.reduce((acc, atual) => {
      let buscasPermitidas = [
        'marca_id',
        'modelo_id',
        'versao_id'
      ]
      acc[`${atual}`] = {
        filtrado: [],
        termo: '',
        permitirBusca: buscasPermitidas.includes(atual)
      }

      return acc
    }, [])
  },
  mounted () {
    this.listas = this.listagens.reduce((acc, atual) => {
      acc[`${atual}`] = []
      return acc
    }, {})

    this.carregamentos = this.listagens.reduce((acc, atual) => {
      acc[`${atual}`] = false

      return acc
    }, {})

    this.keys = this.listagens.reduce((acc, atual, index) => {
      acc[`${atual}`] = index + 1

      return acc
    }, [])

    this.models = {
      marca_id: MarcaModel,
      modelo_id: ModeloModel,
      versao_id: VersaoModel,
      combustivel_id: CombustivelModel
    }

    if (LocalStorage.has('veiculo-listas')) {
      const listas = LocalStorage.getItem('veiculo-listas')
      this.listas = listas
    } else {
      // buscar todas as listagens
      this.buscarTodasAsListagens()
    }
    this.modelCalcularPreco.form.km = ''
  },
  methods: {
    consultarPlaca (placa = '') {
      // eslint-disable-next-line
      placa = placa ? placa.replace(/[\s-]/g, '').replace('-', '') : null

      if (placa && placa.length > 6) {
        const modelPlaca = new ConsultaPlacaIcarrosModel()
        modelPlaca.getHashedId(placa).then((res) => {
          if (res.erro === 1) {
            this.salvando = false
            this.notificacao('aviso', 'Veículo não encontrado!')
          } else this.placaResponse(res.dados)
        }).catch(() => {
          this.salvando = false
          this.notificacao('aviso', 'Veículo não encontrado!')
        })
      }
    },

    placaResponse (res) {
      if (res) {
        this.modalveiculos = false

        let camposResponse = clone(res)
        delete camposResponse['situacao_bem']

        let dados = res.dados_consulta
        delete dados.motor

        let camposGenericos = Object.keys(camposResponse).filter(campo => campo !== 'dados_consulta')
        camposGenericos.map(campo => {
          this.modelVeiculosEntrada.form[`${campo}_id`] = camposResponse[campo].id
        })
        this.modelVeiculosEntrada.form.cambio_id = dados['cambioAutomatico'] === 0 ? 2 : 1

        if (camposResponse && camposResponse.versao_precificadores && camposResponse.versao_precificadores.length > 0) {
          let veiculoValor = parseInt(parseInt(this.$options.filters.soNumeros(camposResponse.versao_precificadores[0].valor)) / 100).toString()
          this.modelCalcularPreco.form.valor_fipe = (veiculoValor * 100)
          this.modelBitrixDealCareca.form.DEAL.OPPORTUNITY = veiculoValor
          this.modelBitrixDealCareca.form.DEAL.UF_CRM_1600859637 = veiculoValor
          this.modelBitrixDealCareca.form.DEAL.UF_CRM_1600913147 = veiculoValor
        }

        if (camposResponse && camposResponse.marca) {
          this.modelBitrixDealCareca.form.DEAL.UF_CRM_1600913067 = camposResponse.marca.nome
        }

        if (camposResponse && camposResponse.modelo) {
          this.modelBitrixDealCareca.form.DEAL.UF_CRM_1600913084 = camposResponse.modelo.nome
        }
        this.modelBitrixDealCareca.form.DEAL.UF_CRM_1651977119 = window.location.pathname

        Object.keys(dados).map(campo => {
          switch (campo) {
            case 'marcaId':
              this.modelVeiculosEntrada.form.marca_id = dados[`${campo}`]
              break
            case 'anoFabricacao':
              this.modelVeiculosEntrada.form.ano_fabricacao = dados[`${campo}`]
              break
            case 'anoModelo':
              this.modelVeiculosEntrada.form.ano_modelo = dados[`${campo}`]
              break
            case 'combustivelId':
              this.modelVeiculosEntrada.form.combustivel_id = dados[`${campo}`]
              break
            default:
              break
          }
        })

        if (this.salvando) this.submitBitrixDealCareca()
      }
    },

    validarCPF (cpf) {
      cpf = this.$options.filters.soNumeros(cpf)
      return cpf && cpf.length >= 11 ? this.validarCPFCalculo(cpf.toString()) : null
    },

    validarCPFCalculo (strCPF) {
      var Soma = 0
      var Resto = null
      if (strCPF === '00000000000') return false

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      Resto = (Soma * 10) % 11

      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      Resto = (Soma * 10) % 11

      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false
      return true
    },

    validarEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    // Methods Listagem
    buscarTodasAsListagens () {
      this.buscandoTodasAsListagens = true
      let promises = this.listagens.map(tipo => {
        // let model = new this.models[tipo]()
        // return model.getListagem({ params: { limit: 1000 } })
        if (tipo !== 'modelo_id' && tipo !== 'versao_id') {
          let model = new this.models[tipo]()
          return model.getListagem({ params: { limit: 1000 } })
        } else {
          return new Promise((resolve) => {
            resolve(true)
          })
        }
      })
      Promise.all(promises)
        .then(response => {
          this.listagens.forEach((tipo, index) => {
            this.listas[tipo] = response[index].dados ?? []
          })
          let listas = this.listas
          if (LocalStorage.has('veiculo-listas')) {
            listas = LocalStorage.getItem('veiculo-listas')
            listas = [...listas, ...this.listas]
          }
          LocalStorage.set('veiculo-listas', this.listas)
          this.buscandoTodasAsListagens = false
        })
        .catch(() => {
          this.buscandoTodasAsListagens = false
        })
    },

    buscarListagem (tipo) {
      if (this.listagens.includes(tipo)) {
        switch (tipo) {
          case 'modelo_id':
            this.buscarListagemRequest(tipo, false, `marca_id:${this.modelVeiculosEntrada.form.marca_id}`)
            break
          case 'versao_id':
            this.buscarListagemRequest(tipo, false, `modelo_id:${this.modelVeiculosEntrada.form.modelo_id}`)
            break
          default:
            this.buscarListagemRequest(tipo)
            break
        }
      }
    },

    buscarListagemRequest (tipo, promise, search = '', relacao = false) {
      let model = new this.models[`${tipo}`](relacao)
      this.carregamentos[`${tipo}`] = true
      if (promise) {
        return model.getListagem({ params: { search, limit: 1000, orderBy: relacao ? '' : 'nome', sortedBy: 'asc' } })
      } else {
        model
          .getListagem({ params: { search, limit: 1000, orderBy: 'nome', sortedBy: 'asc' } })
          .then(response => {
            this.carregamentos[`${tipo}`] = false

            if (tipo === 'versao_id') {
              let data = []
              data[1] = this.removeDuplicates(response.dados, 'nome', this.modelVeiculosEntrada.form.versao_id)
              response.dados = data[1]
            }

            this.listas[`${tipo}`] = response.dados ?? []
            if (LocalStorage.has('veiculo-listas')) {
              let listas = LocalStorage.getItem('veiculo-listas')
              listas[`${tipo}`] = response.dados
              LocalStorage.setItem(`veiculo-listas`, listas)
              this.keys[`${tipo}`]++
            }
            if (tipo === 'versao_id') {
              setTimeout(() => {
                this.buscarPrecificadores('ano_modelo')
              }, 1250)
            }
          })
          .catch(() => {
            this.carregamentos[`${tipo}`] = false
          })
      }
    },
    removeDuplicates (myArr, prop, incluir = null) {
      return myArr.filter((obj, pos, arr) => {
        if (incluir !== null) {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos || incluir === obj['id']
        } else {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        }
      })
    },
    filtrarListagem (tipo) {
      const lista = clone(this.listas[`${tipo}`])
      const regex = new RegExp(this.filtrosListagens[`${tipo}`].termo, 'i')
      this.filtrosListagens[`${tipo}`].filtrado = lista.filter(l => l.nome.match(regex))
      this.listas[`${tipo}`] = this.filtrosListagens[`${tipo}`].filtrado
    },
    resetarListagem (tipo) {
      this.filtrosListagens[`${tipo}`].termo = ''
      this.buscarListagem(tipo)
    },
    /* */

    submitBitrix () {
      this.salvando = true
      this.notificacao('enviando')

      this.consultarPlaca(this.modelBitrixDealCareca.form.DEAL.UF_CRM_1600913053)
    },

    submitBitrixDealCareca () {
      this.salvando = true
      const aviso = this.notificacao('enviando')

      let send = clone(this.modelBitrixDealCareca.form)

      let nomeCompleto = send.LEAD.NAME
      let nomesArr = nomeCompleto.split(' ')

      if (nomesArr[0]) send.LEAD.NAME = nomesArr[0]
      if (nomesArr[1]) {
        send.LEAD.SECOND_NAME = nomesArr[1]
      } else {
        send.LEAD.SECOND_NAME = nomesArr[0]
      }
      if (nomesArr[2]) {
        send.LEAD.LAST_NAME = nomesArr[2]
      } else {
        send.LEAD.LAST_NAME = nomesArr[0]
      }

      if (this.logado) {
        send.bitrix_lead_id = this.logado.bitrix_lead_id
      }

      if (this.modelVeiculosEntrada.form.ano_fabricacao) {
        send.DEAL.UF_CRM_1600913106 = this.modelVeiculosEntrada.form.ano_fabricacao.toString()
      }

      this.modelBitrixDealCareca.salvar(send).then((res) => {
        this.salvando = false
        aviso()
        this.notificacao('salvo', 'Mensagem enviada', 8000)
        this.modalCalcularPreco = true

        this.modelVeiculosEntrada.form.placa = this.modelBitrixDealCareca.form.DEAL.UF_CRM_1600913053
      }).catch((error) => {
        this.salvando = false
        aviso()
        this.notificacao('erro', error.msg)
      })
    },

    calcularPreco () {
      this.$refs.formVeiculosEntrada.validate().then(success => {
        if (success) {
          let fipe = this.listas['versao_id'][this.listas['versao_id'].findIndex(ind => this.modelVeiculosEntrada.form.versao_id === ind.id)].codigo_fipe

          if (fipe === undefined) {
            fipe = 1
          } else {
            this.modelCalcularPreco.form.valor_fipe = null
          }
          const model = new PrecificadorModel()
          model
            .getListagem({
              params: {
                search: `codigo:${fipe};ano:${this.modelVeiculosEntrada.form.ano_modelo}`,
                searchJoin: 'and',
                orderBy: 'id',
                sortedBy: 'desc'
              }
            })
            .then(response => {
              this.calculandoPreco = true
              const aviso = this.notificacao('enviando')

              const send = clone(this.modelCalcularPreco.form)
              send.placa = this.modelVeiculosEntrada.form.placa
              send.marca_id = this.modelVeiculosEntrada.form.marca_id
              send.modelo_id = this.modelVeiculosEntrada.form.modelo_id
              send.ano_modelo = this.modelVeiculosEntrada.form.ano_modelo
              send.versao_id = this.modelVeiculosEntrada.form.versao_id
              send.combustivel_id = this.modelVeiculosEntrada.form.combustivel_id

              if (!this.modelCalcularPreco.form.valor_fipe || !this.placaLocalizada) {
                if (response && response.dados && response.dados[0] && response.dados[0].valor) {
                  this.modelCalcularPreco.form.valor_fipe = (response.dados[0].valor).replace(/\D/g, '')
                  send.valor_fipe = (response.dados[0].valor).replace(/\D/g, '')
                } else {
                  // this.modelCalcularPreco.form.valor_fipe = 20000
                  // send.valor_fipe = 20000
                  aviso()
                  this.calculandoPreco = false
                  send.valor_fipe = 0
                  this.modelVeiculosEntrada.form.valor_mercado = 0
                  this.modelVeiculosEntrada.form.valor_maximo = 0
                  this.modelVeiculosEntrada.form.valor_minimo = 0
                  this.modelVeiculosEntrada.form.valor_varejo = 0
                  this.resultadosCalcularPreco = null
                  this.notificacao('erro', 'Não encontramos o veículo. Verifique os dados')
                  return
                }
              }

              this.modelCalcularPreco.form = send
              this.modelCalcularPreco.getListagem({ params: send }).then((res) => {
                const r = res && res.dados ? res.dados : null

                if (r) {
                  this.modelVeiculosEntrada.form.valor_maximo = r.valor_maximo_final
                  this.modelVeiculosEntrada.form.valor_minimo = r.valor_minimo_final
                  this.modelVeiculosEntrada.form.valor_mercado = r.valor_trade_in
                  this.modelVeiculosEntrada.form.valor_varejo = parseInt(r.valor_price_guru)
                  this.resultadosCalcularPreco = r
                }

                aviso()
                this.notificacao('salvo', 'Calculado com sucesso', 3000)
                this.calculandoPreco = false
              }).catch(error => {
                aviso()
                this.calculandoPreco = false
                this.notificacao('erro', error.msg)
              })
            })
            .catch(() => {
              this.forceRender++
            })
        } else {
          this.notificacao('erro', 'Todos os campos obrigatórios')
        }
      })
    },

    submitVeiculosEntrada () {
      this.salvandoVeiculoEntrada = true
      const aviso = this.notificacao('enviando')

      let send = clone(this.modelVeiculosEntrada.form)
      send.quero_vender = 1
      if (this.modelCalcularPreco.form.placa_uf) send.uf_placa = this.modelCalcularPreco.form.placa_uf
      if (this.modelCalcularPreco.form.km) send.km = this.modelCalcularPreco.form.km.toString()
      if (this.logado) send.usuario_id = this.logado.id

      this.modelVeiculosEntrada.salvar(send).then((res) => {
        aviso()
        this.notificacao('salvo', 'Salvo com sucesso', 3000)
        this.salvandoVeiculoEntrada = false
        this.modalCalcularPreco = false

        if (this.$refs.modalCadastro && !this.logado) {
          this.modalRegistreConta = true
          this.$refs.modalCadastro.form.nome = this.modelBitrixDealCareca.form.LEAD.NAME
          this.$refs.modalCadastro.form.email = this.modelBitrixDealCareca.form.LEAD.EMAIL[0].VALUE
          this.$refs.modalCadastro.form.documento = this.modelBitrixDealCareca.form.LEAD.UF_CRM_1602009023
          this.$refs.modalCadastro.form.celular = this.modelBitrixDealCareca.form.LEAD.PHONE[0].VALUE
          this.$refs.modalCadastro.form.tipo = this.tipoModal
        } else {
          setTimeout(() => {
            this.irRota({ name: 'painel-inicio' })
          }, 2000)
        }
      }).catch(error => {
        aviso()
        this.salvandoVeiculoEntrada = false
        this.notificacao('erro', error.msg)
      })
    },

    irRota (rota) {
      this.$router.push(rota)
      // let routeData = this.$router.resolve(rota)
      // window.location.href = routeData.href
    }
  },
  filters: {
    tipoNome (tipo) {
      if (tipo) {
        if (tipo === 'tipo_retomada_id') {
          return 'Origem de entrada'
        }
        let nome = tipo.replace('_id', '')
        if (nome) {
          return nome.charAt(0).toUpperCase() + nome.slice(1)
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
}
